<script>
  import Layout from '@layouts/main'
  import Breadcrumb from '@/src/components/Breadcrumb.vue'
  import filterVuetable from '@src/mixins/filterVuetable'
  import DatePicker from '@/src/components/DatePicker.vue'
  import FilterButton from '@/src/components/FilterButton'
  import multiSelectWithService from '@src/components/multiSelectWithService'
  import TrackingUsers from '@src/services/dashboards/TrackingUsers'
  import {format} from 'date-fns'

  const i18nCommon = 'COMMON'
  const i18nKey = 'DASHBOARDS'
  
  export default {
      components: {
          multiSelectWithService,
          Layout,
          Breadcrumb,
          DatePicker,
          FilterButton,
      },
      mixins: [filterVuetable],
  
      data() {
          return {
            responseTraking: [],
            fields: [
              {
                key: 'id',
                sortable: true
              },
              {
                key: 'address',
                label: 'Endereço',
                sortable: true,
              },
              {
                key: 'postalCode',
                label: 'CEP',
                sortable: true,
              },
              {
                key: 'created_at',
                label: 'Data de criação',
                sortable: false
              },
              {
                key: 'latitude',
                label: 'Latitude',
                sortable: true,
              },
              {
                key: 'longitude',
                label: 'Longitude',
                sortable: true,
              }
            ],
              latestCheckinsMarker: null,
              filters: {
                  date: new Date(),
                  users: '',
              },
              userId: 0,
              additionalParameters: {},
              usersParameters: {
                  per_page: 1000,
                  page: 1,
                  orderBy: 'name',
                  sortedBy: 'asc',
                  active: true,
              },
          }
      },
      computed: {
          items() {
              return [
                  {
                      text: 'Home',
                      href: '/',
                  },
                  {
                      text: this.getI18nModified({
                          prefix: i18nKey,
                          suffix: 'TITLES.dashboards',
                          modifier: 2,
                      }),
                      active: true,
                  },
                  {
                      text: this.getI18n(i18nCommon, 'geopositioning'),
                      active: true,
                  },
              ]
          },
          i18nDate() {
              return this.getI18n(i18nCommon, 'date')
          },
          i18nUser() {
              return this.getI18nModified({
                  prefix: 'USERS',
                  suffix: 'TITLES.user',
                  modifier: 2,
              })
          },
      },
      methods: {
        createParameters() {
            return this.formatParameters({
                user_id: this.filters.users.id,
                date:format(this.filters.date, "yyyy-MM-dd"),
            })
        },
        filter() {
          this.additionalParameters = this.createParameters()
          this.getLatestCheckins()
        },
        async getLatestCheckins() {
            const response = await TrackingUsers.fetchAll(
                this.additionalParameters
            )
            .then((response) => {
              return response

            })
            .catch((err) => err)
            this.responseTraking = response.data.data.map((response) => {
              return {
                id: response.id,
                address: response.address,
                created_at: response.created_at,
                datetime: response.datetime,
                latitude: response.location[0],
                longitude: response.location[1],
                postalCode: response.postal_code
              }
            })
        },
      },
  }
</script>
  
  <template>
      <Layout>
          <div class="col-12">
              <Breadcrumb :items="items" />
              <div class="row mt-3 mb-3">
                  <div class="col-sm-8">
                      <b-row>
                          <b-col md="4">
                              <b-form-group
                                  :label="i18nDate"
                                  class="required label-pdv"
                                  label-for="filter-date"
                              >
                                  <date-picker
                                      :value.sync="filters.date"
                                      :max-date="new Date()"
                                      :disable-clean-button="true"
                                      :v-model="filters.date"
                                  />
                              </b-form-group>
                          </b-col>
                          <b-col md="7">
                              <b-form-group
                                  :label="i18nUser"
                                  class="label-pdv"
                                  label-for="filter-user"
                              >
                                <multiSelectWithService
                                    :close-on-select="true"
                                    :id="'filter-users'"
                                    ref="userMultiselect"
                                    v-model="filters.users"
                                    :service="'users'"
                                    :searchable="true"
                                    :multiple="false"
                                    :parameters="usersParameters"
                                />
                              </b-form-group>
                          </b-col>
                      </b-row>
                  </div>
                  <div class="col-sm-4 align-self-center">
                      <div class="d-flex justify-content-end">
                          <filter-button class="col-md-6" @onClick="filter" />
                      </div>
                  </div>
              </div>
          </div>
          <div>
            <b-table striped hover :items="responseTraking" :fields="fields"></b-table>
          </div>
        </Layout>
  </template>
  